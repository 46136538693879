import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import GalleryList from "../components/Gallery/GalleryList";
import { graphql } from "gatsby";
import Gallery from "../components/Gallery/Gallery";
import SimpleHomePageCarousel from "../components/SlickCarousel";
import Video from "../components/Video";
import { Section } from "../components/Layout/Section";
import { HomeHeroTitleSection } from "../components/Layout/HomeHeroTitleSection";
import { Container } from "../components/Layout/Container";
import { SectionTitle } from "../components/Layout/SectionTitle";
import GalleryModule from "../components/Gallery/GalleryModule";


const IndexPage = ({pageContext,data}) => {
  console.log(data)
  const selectedPortfolios = data.template.sections[2].selectedModules
  const selectedPress = data.template.sections[4].selectedModules
  const selectedGalleries = data.template.sections[5].selectedModules
  // const images =selectedPortfolios.map(n => {
  //   return {
  //     uid: n.uid,
  //     image:  n.imageFile?.childImageSharp,
  //     imageURL: n.imageFile?.publicURL,
  //     title:  n.locales.find(l => l.iso === pageContext.lang).title,
  //     value:  n.locales.find(l => l.iso === pageContext.lang).value
  //   }
  // })
  const images =selectedGalleries[0]?.gallery?.galleryImages.map(g => {
    return {
      image: g.galleryImage?.childImageSharp,
      uid: g.galleryImage?.id,
      position: g.position
    }
  })
  const allPress =selectedPress.map(n => {
    return {
      uid: n.uid,
      image:  n.imageFile?.childImageSharp,
      imageURL: n.imageFile?.publicURL,
      title:  n.locales.find(l => l.iso === pageContext.lang).title,
      value:  n.locales.find(l => l.iso === pageContext.lang).value,
      pdf: n.hardCopyFile?.publicURL
    }
  })
  const projects =data.allPortfolio.nodes.map(n => {
    return {
      uid: n.uid,
      image:  n.imageFile?.childImageSharp,
      imageURL: n.imageFile?.publicURL,
      title:  n.locales.find(l => l.iso === pageContext.lang).title,
      value:  n.locales.find(l => l.iso === pageContext.lang).value
    }
  })
  const areas = data.allGallery.group.map(g => {
    return g.nodes[0].tags[0]
  })
  const projectAreas = areas.map(n => {
    return {
      uid: n.uid,
      image:  n.tagImage?.childImageSharp,
      title:  n.locales.find(l => l.iso === pageContext.lang).title,
      value:  `/interiors-exteriors/areas/${n.locales.find(l => l.iso === pageContext.lang).title.split(" ").join("-").toLowerCase()}`
    }
  })
  const bannerImage = data.template.sections[0].items[0].imageFile.publicURL
  return (
  <Layout bannerImage={bannerImage}>
    <Container width="full-size">
      <HomeHeroTitleSection>
       <h3 style={{marginTop:"50px"}}>
         {data.template.sections[1].items[0].locales.find(l => l.iso === pageContext.lang).value}
       </h3>
      </HomeHeroTitleSection>
      <Section>
        {/*<GalleryList>*/}
        {/*    <Gallery images={images} />*/}
        {/*</GalleryList>*/}
        <GalleryModule animate={true} type="image" images={images} />
      </Section>
      <Section>
        <HomeHeroTitleSection style={{paddingTop:"116px"}}>
          <h3 style={{maxWidth:"592px"}}>
            Timeless modern design creates purposeful, functional spaces that elevate the user's experience through elegant simplicity.
          </h3>
        </HomeHeroTitleSection>
      </Section>
      <SectionTitle>
        Projects
      </SectionTitle>
    </Container>
    <Container width="full-size">
      <Section>
        <SimpleHomePageCarousel  options={
          {
            mobileHeight: "500px !important",
            desktopHeight: "600px !important",
            infinite: false
          }
        } width="600px" height="576px" nodes={projects} />
      </Section>
    </Container>
    <Container>
      <Section style={{padding:"16px"}}>
        <SectionTitle style={{marginTop:"116px"}}>
          Our process
        </SectionTitle>
        <Video srcId="786047609" poster="../images/videoPoster.jpg" options={{
          controls: ['progress', 'play', 'current-time', 'fullscreen']
        }}/>
      </Section>
    </Container>
    <Container width="full-size">
      <Section>
        <SectionTitle style={{marginTop:"116px"}}>
          Interiors & Exteriors
        </SectionTitle>
        <SimpleHomePageCarousel plural height="576px" linkText="View all" options={
          {
            mobileHeight: "500px !important",
            desktopHeight: "600px !important"
          }
        } nodes={projectAreas} />
      </Section>
    </Container>
    <Container>
      <SectionTitle style={{marginTop:"116px"}}>
        Featured in
      </SectionTitle>
      <SimpleHomePageCarousel linkText="Read" press options={{
        maxItemHeight:"400px",
        maxItemWidth: "360px",
        slidesToShow: 5,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }} nodes={allPress} />
    </Container>
  </Layout>
)}

export const query = graphql`
    query($templateName: String) {
        template(name: {eq: $templateName}) {
            locales {
                iso
                metaDescription
                metaKeywords
                metaTitle
                value
            }
            sections {
                ... on SectionModuleGet {
                    __typename
                    selectedModules {
                        ... on PressGet {
                            uid
                            type
                            hardCopyFile {
                                publicURL
                            }
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                            locales {
                                iso
                                value
                                content
                                description
                                title
                            }
                        }
                        ... on PortfolioGet {
                            uid
                            type
                            additionalData {
                                locales {
                                    iso
                                    value
                                }
                                key
                            }
                            gallery {
                                galleryImages {
                                    galleryImage {
                                        childImageSharp {
                                            gatsbyImageData(layout: FULL_WIDTH)
                                        }
                                    }
                                }
                            }
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                            locales {
                                iso
                                value
                                content
                                description
                                title
                            }
                            position
                            portfolioUrl
                        }
                        ... on GalleryModuleGet {
                            uid
                            type
                            gallery {
                                galleryImages {
                                    position
                                    galleryImage {
                                        childImageSharp {
                                            gatsbyImageData(quality: 90)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                items {
                    key
                    ... on DataItemAssetGet {
                        __typename
                        imageFile {
                            childImageSharp {
                                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                            }
                            publicURL
                        }
                    }
                    ... on DataItemTextGet {
                        __typename
                        locales {
                            iso
                            value
                        }
                    }
                }
            }
        }
        allPortfolio {
            nodes {
                locales {
                    title
                    content
                    description
                    iso
                    metaDescription
                    metaKeywords
                    metaTitle
                    value
                }
                portfolioUrl
                position
                uid
                imageFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                }
                gallery {
                    galleryImages {
                        galleryImage {
                            uid
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                    }
                    assets {
                        locales {
                            alt
                            iso
                        }
                    }
                    tags {
                        tagImage {
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                        locales {
                            iso
                            title
                        }
                    }
                }
                asset {
                    locales {
                        alt
                        iso
                    }
                }
                additionalData {
                    key
                    locales {
                        iso
                        value
                    }
                }
                authorName
                authorUrl
                category {
                    locales {
                        iso
                        title
                    }
                    uid
                }
            }
        }
        allGallery {
            group(field: tags___locales___title) {
                nodes {
                    tags {
                        uid
                        locales {
                            iso
                            title
                        }
                        tagImage {
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                    }
                }
            }
        }
    }
`

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({data, pageContext}) => {
  const { metaTitle, metaDescription} = data.template.locales.find(l => l.iso === pageContext.lang)
  return  <Seo title={metaTitle} description={metaDescription} />
}
// export const Head = () => <Seo title="List of all areas" />


export default IndexPage
