import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import styled from "styled-components";
import {VscClose} from "react-icons/vsc"
import Fade from "react-reveal/Fade"
import { SectionTitle } from "../Layout/SectionTitle";


const Plyr = lazy(() => import("plyr-react") )

const VideoPlayer = React.forwardRef((props, ref) => {
  const { source, options = null, ...rest } = props

     return <Plyr source={source} options={options} ref={ref} />

})


const VideoWrapper = styled(({ className, children }) => {
  return <div className={className}>
    <div className="poster"></div>
    <div className="poster-wrapper">
      {children}
    </div>
  </div>;
})`
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  width:100%;
  .poster-wrapper, .poster {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom:30px;
    gap:20px;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .poster-wrapper {
    //background: rgba(0, 0, 0, 0.5);
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+66&0+0,0.5+66 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 66%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 66%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 66%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
    z-index: ${({show}) => show ? 0 : 85};
  }
  .poster {
    background-image: ${({ poster }) => poster ? `url(${poster})` : null};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: ${({show}) => show ? 0 : 85};
  }
`

const FadeVideoWrapper = styled((props) => {
  const [animEnded, setAnimEnded] = useState(false)
  useEffect(() => {
    if(props.showVideo) {
      setTimeout(() => {
        setAnimEnded(true)
      },1000)
    }else {
      setAnimEnded(false)
    }
  },[props.showVideo])
  return <div className={props.className} style={{display: animEnded ? 'none' : 'block'}}>
      <Fade when={!props.showVideo}>
        <VideoWrapper show={props.showVideo} poster={props.poster}>
            <SectionTitle
              style={{ marginBottom: "15px", textTransform: "uppercase" }}
              color="white">Let us give you a tour</SectionTitle>
            <button className="play-btn" onClick={props.onClick}>Play the video</button>
        </VideoWrapper>
      </Fade>
    </div>;
})`
  .react-reveal {
    position: absolute;
    left:0;
    bottom: 0;
    top:0;
    width:100%;
    z-index:10;
  }
`
const Video = styled(({ className, srcId, provider, poster, options }) => {
  const videoref = useRef(null)
  const [videoSource, setVideoSource] = useState({})
  const [showVideo, setShowVideo] = useState(false)
  const isSSR = typeof window === "undefined"

  useEffect(() => {
    setVideoSource({
      type: "video",
      sources: [
        {
          src:srcId,
          provider: provider ? provider : "vimeo"
        }
      ],
    })
  },[options, srcId, provider])
  const handlePlay = () => {
    videoref.current.plyr?.play()
    setShowVideo(true)
  }
  const handleClose = () => {
    videoref.current.plyr?.stop()
    setShowVideo(false)
  }

  // }
  return (
!isSSR && <>
  <Suspense fallback={<div />}><div className={className}>
      {/*<Plyrer usePlyr={usePlyr} source={videoSource} poster={poster} options={options} ref={videoref} />*/}
      <VideoPlayer source={videoSource}  options={options} ref={videoref} />
      {
        showVideo && <div onClick={handleClose} className="close-btn">
          <VscClose size={30} color="white" />
        </div>
      }
      {
       <FadeVideoWrapper showVideo={showVideo} poster={poster} onClick={handlePlay} />
      }
    </div>
    </Suspense>
    </>
  );
})`
  position: relative;
  max-height:810px;
  .plyr {
    z-index:5;
    min-height:500px;
  }
  .play-btn {
    background:transparent;
    border:none;
    color:white;
    font-size:17px;
    text-transform: uppercase;
    cursor:pointer;
  }
  .close-btn {
    position: absolute;
    display:flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    width: 30px;
    z-index: 120;
    background: rgba(0, 0, 0, 0.84);
    cursor: pointer;
    .svg {
      color: white;
    }
  }
`;

export default Video;
