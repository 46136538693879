import React from "react"
import styled from "styled-components"
export const HomeHeroTitleSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width:637px;
  margin:auto;
  padding:0px 20px 116px 20px;
  @media screen and (min-width:992px) {
    padding:116px 0;
  }
  h3 {
    max-width:428px;
    margin:auto;
    text-align: center;
    font-size:24px;
    font-weight:300;
    line-height: 44px;
    letter-spacing: 0.02em;
    color: #5C5F62;
    letter-spacing: 2%;
  }
`
